import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Config } from 'src/config/config';
import { roomDTO } from '../models/roomDTO.model';

@Injectable({
  providedIn: 'root'
})
export class VideoconsultaBackendService {

  constructor(private httpClient: HttpClient) { }

  checkCid(conferenceId: number){
    return this.httpClient.get(
      Config.videoConsultaBackend + 
      Config.videoConsultaEndpoints.cid + 
      conferenceId, { responseType: 'text', observe: 'response', reportProgress: true })
  };

  getTokboxCredentials(conferenceId: number, roomDTO: roomDTO){
    return this.httpClient.post(
      Config.videoConsultaBackend +
      Config.videoConsultaEndpoints.room + 
      conferenceId,
      roomDTO, { reportProgress: true }
    );
  };

  blacklistCid(conferenceId: number){
    return this.httpClient.post(
      Config.videoConsultaBackend +
      Config.videoConsultaEndpoints.cid,
      { cid: conferenceId }, { observe: 'response' }
    );
  };
}
