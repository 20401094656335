import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TycComponent } from 'src/app/components/tyc/tyc.component';
import { WaitingRoomComponent } from '../waiting-room/waiting-room.component';


@Component({
  selector: 'app-section',
  templateUrl: './section.component.html',
  styleUrls: ['./section.component.css']
})

export class SectionComponent implements OnInit {

  conferenceId: number;
  isMedic: number;

  patientDNI: string;
  origen: string;
  origensocio: string;
  medicId: string;
  toogleBool: boolean = false;
  

  constructor(private matDialog: MatDialog, private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.conferenceId = parseInt(params["cid"]);
      this.isMedic = params["m"];

      this.medicId = params["identificacionMedico"];
      this.patientDNI = params["numeroDeDocumento"];
      this.origensocio = params["origensocio"];
    })
  }

  changeEvent(event) {
    if (event.target.checked) {
        this.toogleBool= false;
    }
    else {
        this.toogleBool= true;
    }
  }

  abrirModal(){
   this.matDialog.open(TycComponent);
  };

  goToVC(){
      this.router.navigate(
        ["/videoconsulta"], 
        { queryParams: { cid: this.conferenceId, m: this.isMedic }}
      );

        if (this.isMedic == 0) {
          console.log('paso directo a vc');
          this.router.navigate(
            ["/videoconsulta"],
            { queryParams: {cid: this.conferenceId, m: this.isMedic, identificacionMedico: this.medicId, numeroDeDocumento: this.patientDNI, origensocio: this.origensocio } }
          )
        }
  };
}
