import { NgModule } from "@angular/core";
import { Routes, RouterModule } from '@angular/router'
import { BienvenidaVcComponent } from "./pages/bienvenida-vc/bienvenida-vc.component";
import { FinalizadoComponent } from "./pages/finalizado/finalizado.component";
import { NotFoundComponent } from "./pages/not-found/not-found.component";
import { VideoconsultaComponent } from "./pages/videoconsulta/videoconsulta.component";

const routes: Routes = [
    { path: 'bienvenido', component: BienvenidaVcComponent },
    { path: 'videoconsulta', component: VideoconsultaComponent},
    { path: 'finalizada', component: FinalizadoComponent },
    { path: 'videoconsultaInvalida', component: NotFoundComponent },
    { path: '**', redirectTo: '/videoconsultaInvalida', pathMatch: 'full'}
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }