import { Injectable } from '@angular/core';
import * as OT from '@opentok/client';

@Injectable({
  providedIn: 'root'
})
export class OpentokService {

  session: OT.Session;
  token: string;
  
  constructor(){
  }

  getOT(){
    return OT;
  }

  initSession(apiKey, sessionId, token) {
    try {
      this.session = this.getOT().initSession(apiKey, sessionId);
      this.token = token;
      return this.session;
    } catch (error) {
      console.log("Ocurrio un error: {}", error);
    }
  }

  connect() {
    return new Promise((resolve, reject) => {
      this.session.connect(this.token, (err) => {
        if(err){
          reject(err)
        } else {
          resolve(this.session);
        }
      })
    })
  }
}
