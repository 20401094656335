import { Component, OnInit } from '@angular/core';
import { EasydocBackendService } from './service/easydoc-backend.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  constructor(private easydocBackend: EasydocBackendService) {
  }
  
  ngOnInit() {
  }
;
};
