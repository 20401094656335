import { AfterContentInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { MatTooltip, TooltipVisibility } from '@angular/material/tooltip';
import { ActivatedRoute, Router } from '@angular/router';
import { Timer } from 'easytimer.js';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { VideoconsultaBackendService } from 'src/app/service/videoconsulta-backend.service';
import { TerminatePartnerComponent } from '../terminate-partner/terminate-partner.component';


@Component({
  selector: 'app-control-handlers',
  templateUrl: './control-handlers.component.html',
  styleUrls: ['./control-handlers.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class ControlHandlersComponent implements AfterContentInit, OnChanges {

  @ViewChild('tooltip', { read: MatTooltip, static: false }) public tooltip: MatTooltip;
  @Output() tooltipToSend = new EventEmitter<MatTooltip>();
  @Input() session: OT.Session;
  @Input() publisher: OT.Publisher;
  isMedic: number;
  showCloseRecordatorio: boolean = false;
  intervalRecordatorio;
  intervalTimer;
  interval
  camEvent = true;
  micEvent = true;
  colgarDisabled = false;
  tiempo;
  conferenceId: number;
  popupModal : boolean = false;
  streams: Array<OT.Stream> = [];
  medicArrived: boolean = false;
  data: any;


  constructor(private route: ActivatedRoute, 
    private ref: ChangeDetectorRef, 
    private router: Router, 
    private matDialog: MatDialog,
    private videoconsultaBackend: VideoconsultaBackendService)
 {
  }

  ngAfterContentInit() {
    this.route.queryParams.subscribe(params => {
      this.isMedic = params["m"];
    });
  };

  ngOnChanges(changes: SimpleChanges) {
    if (this.isMedic != undefined) {
      if (this.isMedic == 1) {
        this.toggleMic();
        this.startTimer();
      } else {
        this.intervalRecordatorio = setInterval(() => {
          this.openRecordatorio();
          clearInterval(this.intervalRecordatorio);
        }, 5000)
      }
    }
  };

  ngOnDestroy() {
    if (this.intervalRecordatorio) {
      clearInterval(this.intervalRecordatorio);
    }
    const timer = new Timer();
    timer.removeAllEventListeners('secondsUpdated');
    timer.stop();
  }

  hangup() {
    
    if (this.isMedic == 1) {
      this.session.disconnect();
      let currentUrl = this.router.url;
          this.router.navigateByUrl('/finalizada', {skipLocationChange: true}).then(() => {
            this.router.navigate([currentUrl]);
          });
      setTimeout(() => {
        this.endedCall();
        this.router.navigate(
          ["/finalizada"],
          { queryParams: { cid: this.conferenceId, m: this.isMedic } });
      }, 1000);
      this.endedCall();
      
    } else if(this.isMedic == 0){
      const dialogConfig = new MatDialogConfig();
            dialogConfig.disableClose = true;
            dialogConfig.data = {
              conferenceId: this.conferenceId,
              isMedic: this.isMedic,
              session: this.session,
            };
            const dialogRef = this.matDialog.open(TerminatePartnerComponent, dialogConfig);
            dialogRef.afterClosed().subscribe(res => {
              console.log(res);
              if(res){
                console.log('Delete file');
              }
              console.log('final vc despues de settime');
            });
    }

};

  endedCall() {
    this.data.session.disconnect()
    this.videoconsultaBackend.blacklistCid(this.conferenceId).subscribe(resp => {
    })
    this.router.navigate(
      ["/finalizada"],
      { queryParams: { cid: this.conferenceId, m: this.isMedic } });
  };

  toggleMic() {
    this.micEvent = !this.micEvent
    this.publisher.publishAudio(this.micEvent);
  };

  toggleCam() {
    this.camEvent = !this.camEvent;
    this.publisher.publishVideo(this.camEvent);
  };

  startTimer() {
    this.colgarDisabled = true;
    const timer = new Timer();
    timer.start();

    // para que timer aparezca en 00:00:00
    this.tiempo = timer.getTimeValues().toString();

    timer.addEventListener("secondsUpdated", e => {
      this.tiempo = timer.getTimeValues().toString();
      if (timer.getTimeValues().minutes >= 1) {
        this.colgarDisabled = false;
      };

      if (timer.getTimeValues().minutes < 3) {
        document.getElementById("timer").className = "timer1";
      } else if (timer.getTimeValues().minutes < 5) {
        document.getElementById("timer").className = "timer2";
      } else {
        document.getElementById("timer").className = "timer3";
      }
    });
  };

  openRecordatorio() {
    this.tooltipToSend.emit(this.tooltip);
  }
}
