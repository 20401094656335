import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { Config } from 'src/config/config';
import { VideoCallStatisticsModel } from '../models/videoCallStatistics.model';

@Injectable({
  providedIn: 'root'
})
export class EasydocBackendService {

  authBody: string;
  token: string;

  constructor(private httpClient: HttpClient) { }

  auth() {
    this.authBody = this.formatAuthBody();
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
        "Authorization": "Bearer"
      }),
      reportProgress: true
    };
    return this.httpClient.post(Config.easyDocBackend + Config.easyDocEndpoints.auth, this.authBody, httpOptions);
  };

  formatAuthBody() {
    const client_id = 'urg_ClientIdEasyDoc'
    const client_secret = 'urg_ClientSecretEasyDoc'
    const grant_type = 'client_credentials'
    return `client_id=${client_id}&client_secret=${client_secret}&grant_type=${grant_type}`;
  };

  statsVc(videoCallStatsDTO: VideoCallStatisticsModel, isMedic: string) {
    this.token = window.localStorage.getItem('easydocToken');

    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "Authorization": 'Bearer ' + this.token
      })
    };

    return this.httpClient.post(
      Config.easyDocBackend + Config.easyDocEndpoints.statsvc + `${isMedic}`,
      videoCallStatsDTO,
      httpOptions);
  };

  statsVcFinalized(videoCallStatsDTO: VideoCallStatisticsModel, isMedic: string) {
    this.token = window.localStorage.getItem('easydocToken');

    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "Authorization": 'Bearer ' + this.token
      })
    };

    return this.httpClient.post(
      Config.easyDocBackend + Config.easyDocEndpoints.finalizedVideoCall + `${isMedic}`,
      videoCallStatsDTO,
      httpOptions);
  };

  statsByCid(conferenceId: string) {
    this.token = window.localStorage.getItem('easydocToken');

    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "Authorization": 'Bearer ' + this.token
      })
    };

    return this.httpClient.get(
      Config.easyDocBackend + Config.easyDocEndpoints.statsBycid + conferenceId,
      httpOptions);
  };
}
