import { CompileShallowModuleMetadata } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { TokenDTO } from 'src/app/models/tokenDTO.model';
import { EasydocBackendService } from 'src/app/service/easydoc-backend.service';
import { VideoconsultaBackendService } from 'src/app/service/videoconsulta-backend.service';

@Component({
  selector: 'app-bienvenida-vc',
  templateUrl: './bienvenida-vc.component.html',
  styleUrls: ['./bienvenida-vc.component.css'],
})
export class BienvenidaVcComponent implements OnInit {

  conferenceId: number;
  isMedic: number;
  token: string;
  medicId: string;
  medicName: string;
  patientDNI: string;
  origen: string;
  origensocio: string;

  constructor(public route: ActivatedRoute,
    private router: Router,
    private videoConsultaBackend: VideoconsultaBackendService,
    private easydocBackend: EasydocBackendService,
    private dialog: MatDialog,
    private SpinnerService: NgxSpinnerService
  ) { }


  ngOnInit() {
    this.SpinnerService.show();

    // Inicio flujo
    this.route.queryParams.subscribe((params) => {

      // Agarro parametros URL
      this.conferenceId = parseInt(params["cid"]);
      this.isMedic = params["m"];

      if (this.isMedic == 1) {
        this.medicId = params["identificacionMedico"];
        this.medicName = params["nombreMedico"];
        this.patientDNI = params["numeroDeDocumento"];
        this.origen = params["origen"];
      };
      if (this.isMedic == 0) {
        this.medicId = params["identificacionMedico"];
        this.patientDNI = params["numeroDeDocumento"];
        this.origensocio = params["origensocio"];
      };


      if (!params["m"] || !params["cid"]) {
        this.router.navigate(["/videoconsultaInvalida"]);
      }
    });

    if (this.conferenceId) {

      // Valido el conferenceID
      this.videoConsultaBackend.checkCid(this.conferenceId).subscribe(body => {
        if (body.status === 200) {
          // Pido token para acceder a easydoc
          //this.easydocBackend.auth().subscribe((body: TokenDTO) => {
            this.SpinnerService.hide();
            //window.localStorage.setItem('easydocToken', body.accessToken);
            //this.token = window.localStorage.getItem('easydocToken');

            //if (this.token.length > 0 && this.isMedic == 1) {
            if (this.isMedic == 1) {
              this.router.navigate(
                ["/videoconsulta"],
                { queryParams: { cid: this.conferenceId, m: this.isMedic, identificacionMedico: this.medicId, nombreMedico: this.medicName, numeroDeDocumento: this.patientDNI, origen: this.origen } }
              )
            }
            /* else if (this.token.length > 0 && this.isMedic == 0) {
              console.log('paso directo a vc');
              this.router.navigate(
                ["/videoconsulta"],
                { queryParams: {cid: this.conferenceId, m: this.isMedic, identificacionMedico: this.medicId, numeroDeDocumento: this.patientDNI, origensocio: this.origensocio } }
              )
            } */
            /* else if (this.token.length > 0) {
              return;
            }; */
          //});
        }
      }, err => {
        if (err.status === 400) {
          this.router.navigate(
            ["/finalizada"],
            { queryParams: { cid: this.conferenceId, m: this.isMedic } })
        }
      });



    } else {
      this.router.navigate(["/videoconsultaInvalida"])
    };

  };
}
