import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { HttpClientModule } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule, MatButtonModule ,MatIconModule, MatProgressBarModule, MatProgressSpinnerModule, MatTooltipModule } from '@angular/material';
import { OpentokService } from './opentok.service';
import { AppRoutingModule } from './app-routing.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AppComponent } from './app.component';
import { BienvenidaVcComponent } from './pages/bienvenida-vc/bienvenida-vc.component';
import { TycComponent } from './components/tyc/tyc.component';
import { FinalizadoComponent } from './pages/finalizado/finalizado.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { SectionComponent } from './components/section/section.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { VideoconsultaComponent } from './pages/videoconsulta/videoconsulta.component';
import { PublisherComponent } from './components/publisher/publisher.component';
import { SubscriberComponent } from './components/subscriber/subscriber.component';
import { ControlHandlersComponent } from './components/control-handlers/control-handlers.component';
import { WaitingRoomComponent } from './components/waiting-room/waiting-room.component';
import { Utils } from './utils/utils';
import { PartnerCallEndedComponent } from './components/partner-call-ended/partner-call-ended.component';
import { TerminatePartnerComponent } from './components/terminate-partner/terminate-partner.component';
import { InformationComponent } from './components/information/information.component';
import { DoctorCallEndedComponent } from './components/doctor-call-ended/doctor-call-ended.component';
import { PartnerCallNetworkDisconnectedComponent } from './components/partner-call-network-disconnected/partner-call-network-disconnected.component';
import { DoctorCallNetworkDisconnectedComponent } from './components/doctor-call-network-disconnected/doctor-call-network-disconnected.component';

@NgModule({
  declarations: [
    AppComponent,
    BienvenidaVcComponent,
    FooterComponent,
    HeaderComponent,
    SectionComponent,
    TycComponent,
    FinalizadoComponent,
    NotFoundComponent,
    VideoconsultaComponent,
    PublisherComponent,
    SubscriberComponent,
    ControlHandlersComponent,
    WaitingRoomComponent,
    PartnerCallEndedComponent,
    TerminatePartnerComponent,
    InformationComponent,
    DoctorCallEndedComponent,
    PartnerCallNetworkDisconnectedComponent,
    DoctorCallNetworkDisconnectedComponent
    
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    NgbModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatButtonModule,
    MatProgressBarModule,
    MatTooltipModule,
    MatIconModule,
    NgxSpinnerModule
  ],
  entryComponents: [TycComponent, PartnerCallEndedComponent, TerminatePartnerComponent, InformationComponent, DoctorCallEndedComponent,PartnerCallNetworkDisconnectedComponent,DoctorCallNetworkDisconnectedComponent],


  providers: [OpentokService, Utils],
  bootstrap: [AppComponent]
})
export class AppModule { }
