import { environment } from "src/environments/environment";

export class Config {

    public static getEnv(){
        console.log("Ambiente: ", environment.ambiente);
    };

    public static easyDocEndpoints = {
        auth: "/auth",
        statsvc: "/statsvc?m=",
        finalizedVideoCall: "/statsvc/finalizedVideoCall?m=",
        statsBycid: "/statsvc/cid/"
    };

    public static videoConsultaEndpoints = {
        room: "/room/",
        cid: "/cid/",
    };

    public static easyDocBackend = environment.server;
    public static videoConsultaBackend = environment.vcServer;
}

Config.getEnv();