import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from "@angular/core";
import { OpentokService } from "src/app/opentok.service";
import { MatDialog } from "@angular/material/dialog";
import { InformationComponent } from "src/app/components/information/information.component";

const publish = () => {};

@Component({
  selector: "app-publisher",
  templateUrl: "./publisher.component.html",
  styleUrls: ["./publisher.component.css"],
})
export class PublisherComponent implements AfterViewInit {
  @ViewChild("publisherDiv", null) publisherDiv: ElementRef;
  @Input() session: OT.Session;
  @Output() publisherToSend = new EventEmitter<OT.Publisher>();

  publisher: OT.Publisher;
  publishing: Boolean;

  constructor(
    private opentokService: OpentokService,
    private matDialog: MatDialog
  ) {
    this.publishing = false;
  }

  ngAfterViewInit() {
    const OT = this.opentokService.getOT();
    this.publisher = OT.initPublisher(
      "minivideo", //this.publisherDiv.nativeElement,
      {
        insertMode: "append",
        style: { buttonDisplayMode: "off", audioLevelDisplayMode: "off" },
        audioBitrate: 20000,
        fitMode: "contain",
      }
    );

    if (this.session) {
      if (this.session["isConnected"]()) {
        this.publish();
      }
      this.session.on("sessionConnected", () => this.publish());
    }

    document.getElementById("minivideo").appendChild(this.publisher.element);
    this.publisher.element.style.width = "154px";
    this.publisher.element.style.height = "123px";
  }

  publish() {
    this.session.publish(this.publisher, (err: any) => {
      if (err) {
        if (err.code == 1500) {
          // Camara y video no disponibles
          const mensaje =
            "Es necesario el uso de audio y video para poder acceder a la videoconsulta";
          this.matDialog.open(InformationComponent, {
            data: {
              mensaje: mensaje,
            },
          });
          // this.publisher.destroy();
          // this.session.disconnect();
        } else {
          alert(err.message);
        }
      } else {
        this.publishing = true;
      }
    });
    this.sendPublisher();
  }

  sendPublisher() {
    this.publisherToSend.emit(this.publisher);
  }
}
