import { Component, Inject, Input, OnInit } from '@angular/core';
import { VideoconsultaBackendService } from 'src/app/service/videoconsulta-backend.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ControlHandlersComponent } from '../control-handlers/control-handlers.component';
import { OpentokService } from 'src/app/opentok.service';


@Component({
  selector: 'app-partner-call-ended',
  templateUrl: './partner-call-ended.component.html',
  styleUrls: ['./partner-call-ended.component.css']
})
export class PartnerCallEndedComponent implements OnInit {

  conferenceId: number;
  isMedic: string;
  data: any;
  constructor(
    private videoconsultaBackend: VideoconsultaBackendService,
    private router: Router,
    private matDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) dialogData) {
    this.data = dialogData;
    this.conferenceId = dialogData.conferenceId;
    this.isMedic = dialogData.isMedic;
    
  }

  ngOnInit() {
  }

  exitVC() {
    console.log("PASA POR : exitVC")
    setTimeout(() => {
      this.endedCall();
      this.router.navigate(
        ["/finalizada"],
        { queryParams: { cid: this.conferenceId, m: this.isMedic } });
    }, 1000);
    this.endedCall();
    this.matDialog.closeAll();  
    
  };

  endedCall() {
    console.log("PASA POR : endedCall")
    console.log("session", this.data.session);
    this.data.session.disconnect()
    this.videoconsultaBackend.blacklistCid(this.conferenceId).subscribe(resp => {
    })
    this.router.navigate(
      ["/finalizada"],
      { queryParams: { cid: this.conferenceId, m: this.isMedic } });
      
  }; 
}
