import { Injectable } from "@angular/core";
import { DeviceDetectorService } from 'ngx-device-detector';
import * as moment from 'moment'
import { EasydocBackendService } from "../service/easydoc-backend.service";

@Injectable()
export class Utils {

    constructor(
        public deviceService: DeviceDetectorService,
        public easydocBackend: EasydocBackendService) { }

    /**
     * @param pattern - Se espera como parametro un formato de tipo fecha, hora, segundos ej. YYYY-DD-MM
     * @returns Fecha tipo String con el formato pasado desde el parametro.
     */
    public getSpecificDateType(pattern: string) {
        return moment().format(pattern);
    };


    /**
     * 
     * @param name Se espera como param el nombre completo envíado por urgencias através de la URL ej. formato esperado - pablo$gonzalez / pablo$julian$gonzalez
     * @returns Nombre/s y apellido separado con espacios
     */
    public formatMedicNameParam(name: string) {
        let finalName = name.split("$");
        let space = " ";

        if (finalName.length == 3) {
            let name1 = finalName[0];
            let name2 = finalName[1];
            let name3 = finalName[2];
            return name1.concat(space, name2, space, name3);
        } else {
            let name1 = finalName[0];
            let name2 = finalName[1]
            return name1.concat(space, name2);
        };
    };

    public getBrowser() {
        const space = " ";
        return this.deviceService.browser.concat(space, this.deviceService.browser_version);
    };

    public getDeviceAndOs() {
        const space = " ";
        if (this.deviceService.device == "Unknown") {
            const device = "PC";
            const deviceAndOs = device.concat(space, this.deviceService.os, space, this.deviceService.os_version);
            return deviceAndOs;
        } else {
            const deviceAndOs = this.deviceService.device.concat(space, this.deviceService.os, space, this.deviceService.os_version);
            return deviceAndOs;
        }
    };

    public getNewToken() {
        return this.easydocBackend.auth();
    };

    public refreshTokenLocalStorage(token: string) {
        return localStorage.setItem('easydocToken', token);
    }
}