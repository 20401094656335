import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { TokenDTO } from 'src/app/models/tokenDTO.model';
import { VideoCallStatisticsModel } from 'src/app/models/videoCallStatistics.model';
import { EasydocBackendService } from 'src/app/service/easydoc-backend.service';
import { Utils } from 'src/app/utils/utils';

@Component({
  selector: 'app-finalizado',
  templateUrl: './finalizado.component.html',
  styleUrls: ['./finalizado.component.css'],
  providers: [DeviceDetectorService, Utils]
})
export class FinalizadoComponent implements OnInit {

  conferenceId: string;
  isMedic: string;
  fechaMedic: string;
  fechaPaciente: string;

  constructor(
    private easydocBackend: EasydocBackendService,
    public route: ActivatedRoute,
    private utils: Utils) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.conferenceId = params["cid"];
      this.isMedic = params["m"];
    });
    this.getStatisticsByCid();
  }

  getStatisticsByCid() {
    this.easydocBackend.statsByCid(this.conferenceId).subscribe((resp: VideoCallStatisticsModel) => {
      if (this.isMedic == "0" && resp.patientFinalizedAt != null) {
        this.fechaPaciente = resp.patientFinalizedAt;
      } else {
        this.fechaPaciente = this.utils.getSpecificDateType("YYYY-MM-DD HH:mm:ss");
      }

      if (this.isMedic == "1" && resp.medicFinalizedAt != null) {
        this.fechaMedic = resp.medicFinalizedAt;
      } else {
        this.fechaMedic = this.utils.getSpecificDateType("YYYY-MM-DD HH:mm:ss");
      }
    }, (err: HttpErrorResponse) => {
      if (err.status == 401) {
        this.utils.getNewToken().subscribe((resp: TokenDTO) => {
          this.utils.refreshTokenLocalStorage(resp.accessToken);
          this.getStatisticsByCid();
        })
      };
    });
  }

}
