import { ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import * as OT from "@opentok/client";
import { DeviceDetectorService } from "ngx-device-detector";
import { NgxSpinnerService } from "ngx-spinner";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { roomDTO } from "src/app/models/roomDTO.model";
import { tokboxCredentials } from "src/app/models/tokboxCredentials.model";
import { VideoCallStatisticsModel } from "src/app/models/videoCallStatistics.model";
import { OpentokService } from "src/app/opentok.service";
import { EasydocBackendService } from "src/app/service/easydoc-backend.service";
import { VideoconsultaBackendService } from "src/app/service/videoconsulta-backend.service";
import { Utils } from "src/app/utils/utils";
import { PartnerCallEndedComponent } from "src/app/components/partner-call-ended/partner-call-ended.component";
import { DoctorCallEndedComponent } from "src/app/components/doctor-call-ended/doctor-call-ended.component";
import { HttpErrorResponse } from "@angular/common/http";
import { MatTooltip, TooltipVisibility } from "@angular/material/tooltip";
import { TokenDTO } from "src/app/models/tokenDTO.model";
import { InformationComponent } from "src/app/components/information/information.component";
import { delay } from "rxjs/operators";
import { Location } from '@angular/common';
import { PartnerCallNetworkDisconnectedComponent } from "src/app/components/partner-call-network-disconnected/partner-call-network-disconnected.component";
import { DoctorCallNetworkDisconnectedComponent } from "src/app/components/doctor-call-network-disconnected/doctor-call-network-disconnected.component";

@Component({
  selector: "app-videoconsulta",
  templateUrl: "./videoconsulta.component.html",
  styleUrls: ["./videoconsulta.component.css"],
  providers: [OpentokService, DeviceDetectorService, Utils],
})
export class VideoconsultaComponent implements OnInit {
  tooltip: MatTooltip;
  session: OT.Session;
  streams: Array<OT.Stream> = [];
  changeDetectorRef: ChangeDetectorRef;
  tooltipDisable: TooltipVisibility = "hidden";

  videoCallstatisticsDTO: VideoCallStatisticsModel;
  conferenceId: number;
  isMedic: number;
  medicId: string;
  medicName: string;
  patientDNI: string;
  publisher: OT.Publisher;
  enableWaitingRoom: boolean = false;
  showWaitingRoom: boolean = false;
  medicArrived: boolean = false;
  reasondisconnectionpatient: string;
  reasondisconnectionmedic: string;
  streamfinished: string;
  origen: string;
  origensocio: string;

  inicioEstadisticas: string = "inicio";
  finalEstadisticas: string = "final";
  reason: string = "reason";

  constructor(
    private ref: ChangeDetectorRef,
    private route: ActivatedRoute,
    private opentokService: OpentokService,
    private matDialog: MatDialog,
    private videoconsultaBackend: VideoconsultaBackendService,
    private easydocBackend: EasydocBackendService,
    private spinnerService: NgxSpinnerService,
    public deviceService: DeviceDetectorService,
    private router: Router,
    public utils: Utils,
    public location: Location,
  ) {
    this.changeDetectorRef = ref;
  }

  ngOnInit() {
    this.spinnerService.show();
    // Obtener params
    this.route.queryParams.subscribe((params) => {
      this.conferenceId = parseInt(params["cid"]);
      this.isMedic = params["m"];

      if (this.isMedic == 1) {
        this.medicId = params["identificacionMedico"];
        this.medicName = params["nombreMedico"];
        this.patientDNI = params["numeroDeDocumento"];
        this.origen = params["origen"];
      }
      if (this.isMedic == 0) {
        this.medicId = params["identificacionMedico"];
        this.patientDNI = params["numeroDeDocumento"];
        this.origensocio = params["origensocio"];
      };
    });

    if (this.isMedic == 1) {
      this.sendStatistics(this.inicioEstadisticas);
      this.videoconsultaBackend.checkCid(this.conferenceId).subscribe(
        (resp) => {
          return;
        },
        (err: HttpErrorResponse) => {
          if (err.status === 400) {
            this.router.navigate(
              ["/finalizada"],
              { queryParams: { cid: this.conferenceId, m: this.isMedic } })
          }
        }
      );
    } else if (this.isMedic == 0) {
      this.sendStatistics(this.inicioEstadisticas);
    }

    //Obtengo credenciales tokbox
    const isSafari = new roomDTO(0);
    this.videoconsultaBackend
      .getTokboxCredentials(this.conferenceId, isSafari)
      .subscribe((credentials: tokboxCredentials) => {
        this.session = this.opentokService.initSession(
          credentials.apiKey,
          credentials.sessionId,
          credentials.token
        );

        this.session.on("connectionCreated", (event) => {
          this.spinnerService.hide();
          this.showWaitingRoom = true;
          if (this.isMedic == 1) {
            this.medicArrived = true;
          }
        });

        this.session.on("connectionDestroyed", (event) => {
        });


        this.session.on("streamCreated", (event) => {
          // Este evento detecta el inicio del streaming - el momento donde los dos estan conectados
          this.spinnerService.hide().then((e) => {
            const intervalRecordatorio = setInterval(() => {
              this.tooltip.toggle();
              clearInterval(intervalRecordatorio);
            }, 3000);
          });
          this.medicArrived = true;
          if (this.isMedic == 0) {
            this.matDialog.closeAll();
          }
          this.streams.push(event.stream);
          this.changeDetectorRef.detectChanges();
        });

        this.session.on("streamDestroyed", (event) => {
          // Detecta cuando el subscriber se va del stream por cualquier razón
          if (this.isMedic == 1) {
            if (event.reason == "clientDisconnected") {
              this.reasondisconnectionpatient = "Recargo su navegador";
              this.reasondisconnectionmedic = "Recargo su navegador";

            } else if (event.reason == "networkDisconnected") {
              this.reasondisconnectionpatient = "Perdio su conexion a internet";
            }
            const dialogConfig = new MatDialogConfig();
            dialogConfig.disableClose = true;
            dialogConfig.data = {
              conferenceId: this.conferenceId,
              isMedic: this.isMedic,
              session: this.session,
            };
            if (event.reason == "networkDisconnected") {
              this.matDialog.open(PartnerCallNetworkDisconnectedComponent, dialogConfig);

            } else {
              this.matDialog.open(PartnerCallEndedComponent, dialogConfig);
            }
            this.sendStatistics(this.reason);

          } else if (this.isMedic == 0) {

            if (event.reason == "clientDisconnected") {
              this.reasondisconnectionmedic = "Recargo su navegador";
              this.reasondisconnectionpatient = "Recargo su navegador";

            } else if (event.reason == "networkDisconnected") {
              this.reasondisconnectionmedic = "Perdio su conexion a internet";
            }
            this.sendStatistics(this.reason);
            const dialogConfig = new MatDialogConfig();
            dialogConfig.disableClose = true;
            dialogConfig.data = {
              conferenceId: this.conferenceId,
              isMedic: this.isMedic,
              session: this.session,
            };
            if (event.reason == "networkDisconnected") {
              const dialogRef =this.matDialog.open(DoctorCallNetworkDisconnectedComponent, dialogConfig);
              dialogRef.afterClosed().subscribe(res => {
                //console.log(res);
                if (res) {
                  console.log('Delete file');
                }
                setTimeout(() => {
                  this.matDialog.closeAll();
                  /* this.dialogRef.close(); */
                  console.log('salgo de la alerta - doctorcall');
                }, 1000);
                console.log('final vc despues de settime');
              });

            } else {
              const dialogRef = this.matDialog.open(DoctorCallEndedComponent, dialogConfig);
              dialogRef.afterClosed().subscribe(res => {
                //console.log(res);
                if (res) {
                  console.log('Delete file');
                }
                setTimeout(() => {
                  this.matDialog.closeAll();
                  /* this.dialogRef.close(); */
                  console.log('salgo de la alerta - doctorcall');
                }, 1000);
                console.log('final vc despues de settime');
              });
            }
          }
        });

        this.session.on('sessionDisconnected', event => {

          if (this.isMedic == 1) {
            this.streamfinished = "Socio";
            setTimeout(() => {
              this.router.navigate(
                ["/finalizada"],
                { queryParams: { cid: this.conferenceId, m: this.isMedic } });
              this.medicArrived = false;
            }, 1000);
            this.router.navigateByUrl("/finalizada", { skipLocationChange: true }).then(() => {
              this.router.navigate([decodeURI(this.location.path())]);
            });
            this.videoconsultaBackend.blacklistCid(this.conferenceId).subscribe(resp => {
            })
            this.sendStatistics(this.finalEstadisticas);
          } else if (this.isMedic == 0) {
            this.streamfinished = "Medico";
            setTimeout(() => {
              this.router.navigate(
                ["/finalizada"],
                { queryParams: { cid: this.conferenceId, m: this.isMedic } });
              this.medicArrived = false;
            }, 1000);
            this.router.navigateByUrl("/finalizada", { skipLocationChange: true }).then(() => {
              this.router.navigate([decodeURI(this.location.path())]);
            });
            this.videoconsultaBackend.blacklistCid(this.conferenceId).subscribe(resp => {
            })
            this.sendStatistics(this.finalEstadisticas);
          }
        });

        this.opentokService.connect().catch((err) => {

          if (err.code == 1500 || err.code == 1006 || err.code == 1008 || err.code == 1013) {
            // ERROR OT_TIMEOUT
            const mensaje =
              "Hubo un error, compruebe su conexión a Internet e intente ingresar nuevamente a la videoconsulta";
            this.matDialog.open(InformationComponent, {
              data: {
                mensaje: mensaje,
              },
            });
          } else {
            alert(err.message);
          }
        });
      });
  }

  ngOnDestroy() {
    this.medicArrived = false;
  }

  getPublisher($event) {
    this.publisher = $event;
  }

  getTooltip(tooltip: MatTooltip) {
    this.tooltip = tooltip;
  }

  endedCall() {

    this.router.navigate(["/finalizada"], {
      queryParams: { cid: this.conferenceId, m: this.isMedic },
    });
    this.medicArrived = false;
  }

  /**
   * Funcion para llamada al servicio de las estadísticas
   *
   * @param param recibe como parametro "inicio" o "final", para determinar el persistido de las estadisticas
   */
  sendStatistics(param: string) {
    // this.isMedic == 1 ? "true" : "false"
    if (this.isMedic == 1) {
      const medico = "true";
      if (param == "inicio") {
        this.videoCallstatisticsDTO = this.buildStatistics();
        this.easydocBackend
          .statsVc(this.videoCallstatisticsDTO, medico)
          .subscribe(
            (resp) => { },
            (err) => {
              if (err.status == 401) {
                this.utils.getNewToken().subscribe((resp: TokenDTO) => {
                  this.utils.refreshTokenLocalStorage(resp.accessToken);
                  this.sendStatistics(param);
                });
              }
            }
          );
      } else if (param == "final") {
        this.videoCallstatisticsDTO = this.buildStatisticsFinalized();
        this.easydocBackend
          .statsVcFinalized(this.videoCallstatisticsDTO, medico)
          .subscribe(
            (resp) => { },
            (err) => {
              if (err.status == 401) {
                this.utils.getNewToken().subscribe((resp: TokenDTO) => {
                  this.utils.refreshTokenLocalStorage(resp.accessToken);
                  this.sendStatistics(param);
                });
              }
            }
          );
      } else if (param == "reason") {
        this.videoCallstatisticsDTO = this.buildStatisticsReason();
        this.easydocBackend
          .statsVcFinalized(this.videoCallstatisticsDTO, medico)
          .subscribe(
            (resp) => { },
            (err) => {
              if (err.status == 401) {
                this.utils.getNewToken().subscribe((resp: TokenDTO) => {
                  this.utils.refreshTokenLocalStorage(resp.accessToken);
                  this.sendStatistics(param);
                });
              }
            }
          );
      }
    } else if (this.isMedic == 0) {
      const medico = "false";
      if (param == "inicio") {
        this.videoCallstatisticsDTO = this.buildStatistics();
        this.easydocBackend
          .statsVc(this.videoCallstatisticsDTO, medico)
          .subscribe(
            (resp) => { },
            (err) => {
              if (err.status == 401) {
                this.utils.getNewToken().subscribe((resp: TokenDTO) => {
                  this.utils.refreshTokenLocalStorage(resp.accessToken);
                  this.sendStatistics(param);
                });
              }
            }
          );
      } else if (param == "final") {
        this.videoCallstatisticsDTO = this.buildStatisticsFinalized();
        this.easydocBackend
          .statsVcFinalized(this.videoCallstatisticsDTO, medico)
          .subscribe(
            (resp) => { },
            (err) => {
              if (err.status == 401) {
                this.utils.getNewToken().subscribe((resp: TokenDTO) => {
                  this.utils.refreshTokenLocalStorage(resp.accessToken);
                  this.sendStatistics(param);
                });
              }
            }
          );
      } else if (param == "reason") {
        this.videoCallstatisticsDTO = this.buildStatisticsReason();
        this.easydocBackend
          .statsVcFinalized(this.videoCallstatisticsDTO, medico)
          .subscribe(
            (resp) => { },
            (err) => {
              if (err.status == 401) {
                this.utils.getNewToken().subscribe((resp: TokenDTO) => {
                  this.utils.refreshTokenLocalStorage(resp.accessToken);
                  this.sendStatistics(param);
                });
              }
            }
          );
      }
    }
  }

  /**
   *
   * @returns retorna un objeto modelo para envíar las estadísticas al backend
   */


  buildStatistics(): VideoCallStatisticsModel {
    console.log("pasa por buildStatistics")
    console.log("origen", this.origen)
    console.log("origenSocio", this.origensocio)
    const statisticsDTO = new VideoCallStatisticsModel();

    if (this.isMedic == 1) {
      const attentionDate = this.utils.getSpecificDateType("DD/MM/YYYY");
      const medicName = this.utils.formatMedicNameParam(this.medicName);
      const medicEnteredAt = this.utils.getSpecificDateType("YYY-MM-DD HH:mm:ss");
      const medicBrowser = this.utils.getBrowser();
      const medicDevice = this.utils.getDeviceAndOs();

      statisticsDTO.attentionDate = attentionDate;
      statisticsDTO.conferenceId = this.conferenceId;
      statisticsDTO.medicId = this.medicId;
      statisticsDTO.medicName = medicName;
      statisticsDTO.medicEnteredAt = medicEnteredAt;
      statisticsDTO.medicBrowser = medicBrowser;
      statisticsDTO.medicDevice = medicDevice;
      statisticsDTO.patientDNI = this.patientDNI;
      statisticsDTO.origen = this.origen;
      statisticsDTO.patientEnteredAt = "";
      statisticsDTO.patientBrowser = "";
      statisticsDTO.patientDevice = "";
      statisticsDTO.medicFinalizedAt = "";
      statisticsDTO.patientFinalizedAt = "";
      statisticsDTO.totalDuration = "";
      statisticsDTO.reconnectionMedic = "";
      statisticsDTO.reconnectionPatient = "";
      statisticsDTO.origen = this.origen;
      statisticsDTO.origensocio = "";

      return statisticsDTO;
    } else if (this.isMedic == 0) {

      const attentionDate = this.utils.getSpecificDateType("DD/MM/YYYY");
      const patientEnteredAt = this.utils.getSpecificDateType("YYY-MM-DD HH:mm:ss");
      const patientBrowser = this.utils.getBrowser();
      const patientDevice = this.utils.getDeviceAndOs();

      statisticsDTO.attentionDate = attentionDate;
      statisticsDTO.conferenceId = this.conferenceId;
      statisticsDTO.medicId = this.medicId;
      statisticsDTO.medicName = "";
      statisticsDTO.medicEnteredAt = "";
      statisticsDTO.medicBrowser = "";
      statisticsDTO.medicDevice = "";
      statisticsDTO.patientDNI = this.patientDNI;
      statisticsDTO.patientEnteredAt = patientEnteredAt;
      statisticsDTO.patientBrowser = patientBrowser;
      statisticsDTO.patientDevice = patientDevice;
      statisticsDTO.medicFinalizedAt = "";
      statisticsDTO.patientFinalizedAt = "";
      statisticsDTO.totalDuration = "";
      statisticsDTO.reconnectionMedic = "";
      statisticsDTO.reconnectionPatient = "";
      statisticsDTO.origen = "";
      statisticsDTO.origensocio = this.origensocio;
      return statisticsDTO;
    }
  }

  buildStatisticsFinalized(): VideoCallStatisticsModel {

    const statisticsDTO = new VideoCallStatisticsModel();
    if (this.isMedic == 1) {
      const medicFinalizedAt = this.utils.getSpecificDateType("YYY-MM-DD HH:mm:ss");

      statisticsDTO.attentionDate = "";
      statisticsDTO.conferenceId = this.conferenceId;
      statisticsDTO.medicId = "";
      statisticsDTO.medicName = "";
      statisticsDTO.medicEnteredAt = "";
      statisticsDTO.medicBrowser = "";
      statisticsDTO.medicDevice = "";
      statisticsDTO.patientDNI = "";
      statisticsDTO.patientEnteredAt = "";
      statisticsDTO.patientBrowser = "";
      statisticsDTO.patientDevice = "";
      statisticsDTO.medicFinalizedAt = medicFinalizedAt;
      statisticsDTO.patientFinalizedAt = "";
      statisticsDTO.totalDuration = "";
      statisticsDTO.reconnectionMedic = "";
      statisticsDTO.reconnectionPatient = "";
      statisticsDTO.reasondisconnectionpatient = "";
      statisticsDTO.streamfinished = this.streamfinished;
      statisticsDTO.origen = "";
      statisticsDTO.origensocio = "";

      return statisticsDTO;
    } else {

      const patientFinalizedAt = this.utils.getSpecificDateType("YYY-MM-DD HH:mm:ss");

      statisticsDTO.attentionDate = "";
      statisticsDTO.conferenceId = this.conferenceId;
      statisticsDTO.medicId = "";
      statisticsDTO.medicName = "";
      statisticsDTO.medicEnteredAt = "";
      statisticsDTO.medicBrowser = "";
      statisticsDTO.medicDevice = "";
      statisticsDTO.patientDNI = "";
      statisticsDTO.patientEnteredAt = "";
      statisticsDTO.patientBrowser = "";
      statisticsDTO.patientDevice = "";
      statisticsDTO.medicFinalizedAt = "";
      statisticsDTO.patientFinalizedAt = patientFinalizedAt;
      statisticsDTO.totalDuration = "";
      statisticsDTO.reconnectionMedic = "";
      statisticsDTO.reconnectionPatient = "";
      statisticsDTO.reasondisconnectionmedic = "";
      statisticsDTO.streamfinished = this.streamfinished;
      statisticsDTO.origen = "";
      statisticsDTO.origensocio = "";
      return statisticsDTO;
    }
  }



  buildStatisticsReason(): VideoCallStatisticsModel {

    const statisticsDTO = new VideoCallStatisticsModel();
    if (this.isMedic == 1) {

      statisticsDTO.attentionDate = "";
      statisticsDTO.conferenceId = this.conferenceId;
      statisticsDTO.medicId = "";
      statisticsDTO.medicName = "";
      statisticsDTO.medicEnteredAt = "";
      statisticsDTO.medicBrowser = "";
      statisticsDTO.medicDevice = "";
      statisticsDTO.patientDNI = "";
      statisticsDTO.patientEnteredAt = "";
      statisticsDTO.patientBrowser = "";
      statisticsDTO.patientDevice = "";
      statisticsDTO.medicFinalizedAt = "";
      statisticsDTO.patientFinalizedAt = "";
      statisticsDTO.totalDuration = "";
      statisticsDTO.reconnectionMedic = "";
      statisticsDTO.reconnectionPatient = "";
      statisticsDTO.reasondisconnectionmedic = this.reasondisconnectionmedic;
      statisticsDTO.reasondisconnectionpatient = this.reasondisconnectionpatient;
      statisticsDTO.origen = "";
      statisticsDTO.origensocio = "";

      return statisticsDTO;
    } else {
      statisticsDTO.attentionDate = "";
      statisticsDTO.conferenceId = this.conferenceId;
      statisticsDTO.medicId = "";
      statisticsDTO.medicName = "";
      statisticsDTO.medicEnteredAt = "";
      statisticsDTO.medicBrowser = "";
      statisticsDTO.medicDevice = "";
      statisticsDTO.patientDNI = "";
      statisticsDTO.patientEnteredAt = "";
      statisticsDTO.patientBrowser = "";
      statisticsDTO.patientDevice = "";
      statisticsDTO.medicFinalizedAt = "";
      statisticsDTO.patientFinalizedAt = "";
      statisticsDTO.totalDuration = "";
      statisticsDTO.reconnectionMedic = "";
      statisticsDTO.reconnectionPatient = "";
      statisticsDTO.reasondisconnectionpatient = this.reasondisconnectionpatient;
      statisticsDTO.reasondisconnectionmedic = this.reasondisconnectionmedic;
      statisticsDTO.origen = "";
      statisticsDTO.origensocio = "";

      return statisticsDTO;
    }
  }


}
