import { Component, Inject, Input, OnInit } from '@angular/core';
/* import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog'; */
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { VideoconsultaBackendService } from 'src/app/service/videoconsulta-backend.service';
import { Router } from '@angular/router';



@Component({
  selector: 'app-terminate-partner',
  templateUrl: './terminate-partner.component.html',
  styleUrls: ['./terminate-partner.component.css']
})
export class TerminatePartnerComponent implements OnInit {
  @Input() session: OT.Session;
  conferenceId: number;
  isMedic: number;
  data: any;
  modalPopup: boolean = false;

  constructor(
    private videoconsultaBackend: VideoconsultaBackendService,
    private router: Router,
    public dialogRef: MatDialogRef<TerminatePartnerComponent>,
    @Inject(MAT_DIALOG_DATA) dialogData ) {
      this.data = dialogData;
      this.conferenceId = dialogData.conferenceId;
      this.isMedic = dialogData.isMedic;
    }

  ngOnInit() {
  }

  onClickNo():void{
    this.dialogRef.close();
  }
 
  exitVC() {
    setTimeout(() => {
      this.endedCall();
      this.router.navigate(
        ["/finalizada"],
        { queryParams: { cid: this.conferenceId, m: this.isMedic } });
    }, 1000);
    this.endedCall();
  };

  endedCall() {
    console.log("session", this.data.session);
    this.data.session.disconnect()
    this.videoconsultaBackend.blacklistCid(this.conferenceId).subscribe(resp => {
    })
    this.router.navigate(
      ["/finalizada"],
      { queryParams: { cid: this.conferenceId, m: this.isMedic } });
  }; 

}
