import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { VideoconsultaBackendService } from 'src/app/service/videoconsulta-backend.service';

@Component({
  selector: 'app-doctor-call-network-disconnected',
  templateUrl: './doctor-call-network-disconnected.component.html',
  styleUrls: ['./doctor-call-network-disconnected.component.css']
})
export class DoctorCallNetworkDisconnectedComponent implements OnInit {

  @Input() session: OT.Session;
  conferenceId: number;
  isMedic: number;
  data: any;
  modalPopup: boolean = false;

  //public message: string
  constructor(
    private videoconsultaBackend: VideoconsultaBackendService,
    private router: Router,
    public dialogRef: MatDialogRef<DoctorCallNetworkDisconnectedComponent>,
    @Inject(MAT_DIALOG_DATA) dialogData ) {
      this.data = dialogData;
      this.conferenceId = dialogData.conferenceId;
      this.isMedic = dialogData.isMedic;
    }

  ngOnInit() {

  }

  onClickNo():void{
    setTimeout(() => {
      this.dialogRef.close();
      console.log('salgo de la alerta - doctorcall');
    }, 1000);
    //this.dialogRef.close();
  }

  exitVC() {
    setTimeout(() => {
      this.endedCall();
      this.router.navigate(
        ["/finalizada"],
        { queryParams: { cid: this.conferenceId, m: this.isMedic } });
    }, 1000);
    this.endedCall();
  };

  endedCall() {
    console.log("session", this.data.session);
    this.data.session.disconnect()
    this.videoconsultaBackend.blacklistCid(this.conferenceId).subscribe(resp => {
    })
    this.router.navigate(
      ["/finalizada"],
      { queryParams: { cid: this.conferenceId, m: this.isMedic } });
  }; 

}
