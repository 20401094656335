import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import * as OT from '@opentok/client';

@Component({
  selector: 'app-subscriber',
  templateUrl: './subscriber.component.html',
  styleUrls: ['./subscriber.component.css']
})
export class SubscriberComponent implements AfterViewInit {
  @Input() session: OT.Session;
  @Input() stream: OT.Stream;
  constructor(public spinnerService: NgxSpinnerService) { }

  ngAfterViewInit() {
    this.session.subscribe(
      this.stream,
      "fullvideo",
      {
        insertMode: 'append',
        width: "100%",
        height: "100%",
        style: { buttonDisplayMode: "off", audioLevelDisplayMode: "off" }
      }, (err) => {
        if (err) {
          alert(err.message);
        }
      });
      /* setTimeout(() => {
        this.spinnerService.show();
      }, 1000); */
    /* this.spinnerService.show(); */
  }
}
