import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-information',
  templateUrl: './information.component.html',
  styleUrls: ['./information.component.css']
})
export class InformationComponent implements OnInit {

  mensaje: string;

  constructor(
    private matDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) { 
    this.mensaje = data.mensaje;
  }

  ngOnInit() {
  }

  cerrarModal() {
    this.matDialog.closeAll()
  };

}
